import classnames from "classnames";

export default function Icon({
  style = "fas",
  name,
  className = "",
  margin = null,
  onClick = null,
  size = null,
  ref = null,
}) {
  return (
    <i
      ref={ref}
      className={classnames(
        style,
        `fa-${name}`,
        className,
        {
          "mr-2": margin === true,
          clickable: onClick !== null,
        },
        size != null ? `fa-${size}` : "",
        margin
      )}
      onClick={() => onClick && onClick()}
    ></i>
  );
}

import axios from "axios";
import { useRef, useEffect } from "react";
import { isChrome } from "react-device-detect";
import moment from "moment-timezone";
import * as rasterizeHTML from "next-rasterizehtml";

export function normalize(s) {
  if (!s.normalize) {
    return s;
  }

  return s
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
}

export function formatNumber(value) {
  const numberVal = +value || 0;
  return numberVal.toFixed(2).replace(".", ",");
}

export function getToday(pattern = "YYYY-MM-DD") {
  return moment().format(pattern);
}

export function getLocalDate(date, timezone = moment.tz.guess()) {
  return moment.tz(date, timezone).tz(moment.tz.guess());
}

export function isRunningOnApp() {
  return typeof android !== "undefined";
}

export function canCallNative(func) {
  return isRunningOnApp() && typeof android[func] === "function";
}

export function callNative(func, param) {
  if (!canCallNative(func)) return;

  if (typeof param === "undefined") return android[func]();

  if (typeof param !== "string") param = JSON.stringify(param);

  return android[func](param);
}

export function desktopPrint(content) {
  const iframe = document.createElement("iframe");
  iframe.width = iframe.height = iframe.frameBorder = "0px";
  iframe.src = "about:blank";
  iframe.style.visibility = "hidden";
  iframe.style.display = "none";
  iframe.onload = () => {
    const iframe_window = iframe.contextWindow || iframe;
    const iframe_document = iframe_window.document || iframe.contentDocument;
    const script = iframe_document.createElement("script");
    script.type = "text/javascript";
    const func = iframe_document.createTextNode(
      `window.Print = function() { ${
        isChrome ? "setTimeout(function() {" : ""
      }window.focus(); window.print(); ${isChrome ? "}, 1000);" : ""} }`
    );
    script.appendChild(func);
    iframe_document.body.innerHTML = content;
    iframe_document.body.appendChild(script);
    iframe.contentWindow.Print();
  };
  document.body.appendChild(iframe);
}

export function useIsMountedRef() {
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });
  return isMountedRef;
}

export function createChampionshipHash(championship, props) {
  let hash = props.reduce((items, prop) => {
    if (!championship[prop]) {
      championship[prop] = 0;

      if (prop === 'codigo_pais') {
        championship[prop] = 'int';
      }
    }

    if (items) {
      return `${items};${championship[prop]}`
    }

    return championship[prop]
  }, '')

  return hash;
}

export function requestErrorAlert(error, isAxios = true) {
  if (isAxios) {
    if (error.response) {
      console.error("Data", error.response.data);
      console.error("Status", error.response.status);
      console.error("Headers", error.response.headers);

      if (error.response.status >= 500) {
        alertify.alert(
          "Erro",
          "Um erro interno ocorreu, por favor, tente mais tarde."
        );
      } else {
        alertify.alert("Erro", "Você realizou uma operação inválida.");
      }
    } else if (error.request) {
      console.error("Request", error.request);
      alertify.alert(
        "Erro",
        "Sua operação não foi concluída com sucesso, por favor, tente mais tarde."
      );
    } else {
      console.error("Erro", error.message);
      alertify.alert(
        "Falha",
        "Verifique sua conexão com a Internet e tente novamente."
      );
    }
  } else {
    console.error(error);
    alertify.alert(
      "Falha",
      "Um erro ocorreu enquanto a sua operação era processada, por favor, tente novamente"
    );
  }
}

export function getTicketImageDataUrl(apiUrl, code) {
  return axios.get(`/api/ticket-img/${code}`).then(({ data }) => {
    if (data.status === "error") throw new Error(data.message);
    return data.result;
  });
}

export function generateTicketImageDataUrl(apiUrl, code) {
  return new Promise((resolve, reject) => {
    axios
      .get(`${apiUrl}/bilhete/?codigo=${code}`)
      .then((result) => {
        const html = result.data;
        const iframe = document.createElement("iframe");
        iframe.style.width = "600px";
        iframe.style.height = "auto";
        iframe.src = "about:blank";
        iframe.style.visibility = "hidden";
        document.body.appendChild(iframe);
        iframe.contentWindow.document.write(html);

        setTimeout(() => {
          const canvas = document.createElement("canvas"),
            ticket = iframe.contentWindow.document.querySelector(".ticket");
          canvas.width = ticket.offsetWidth;
          canvas.height = ticket.offsetHeight;

          rasterizeHTML
            .drawHTML(html, canvas)
            .then(() => {
              iframe.remove();
              resolve(canvas.toDataURL("image/jpeg"));
            })
            .catch(reject);
        }, 100);
      })
      .catch(reject);
  });
}

export function getSportId(sport) {
  const sports = {
    soccer: 1,
    basketball: 2,
    mma: 3,
    volley: 4,
    tennis: 5,
    "e-sports": 6,
  };

  return sports[sport] ? sports[sport] : null;
}

export function getInstance(baseURL, protect = true) {
  const options = {
    baseURL,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  };

  if (protect) {
    options["withCredentials"] = true;
  }

  return axios.create(options);
}

export function fetcher(axios, url, data = null, customConfigs = {}) {
  let config = { ...customConfigs };
  let token = null;

  if (localStorage && localStorage.getItem) {
    token = localStorage.getItem("token");
  }

  if (token && axios.defaults.withCredentials) {
    config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
    config.withCredentials = true;
  }

  if (data) {
    if (typeof data === "object" && !(data instanceof FormData))
      data = new URLSearchParams(data).toString();
    return axios.post(url, data, config);
  }

  return axios.get(url, config);
}

export function openWhatsApp(text, phone = "") {
  if (isRunningOnApp()) {
    callNative("shareOnWhatsApp", { text, phone });
    return;
  }

  const query = new URLSearchParams({ text }).toString();
  const url = phone
    ? `https://wa.me/${phone}?${query}`
    : `https://api.whatsapp.com/send/?${query}`;
  window.open(url, "_blank");
}

export async function getDefaultStaticProps() {
  const apiUrl = process.env.API_URL;
  const socketUrl = process.env.SOCKET_URL;
  const oddsUrl = process.env.ODDS_URL || apiUrl;
  const res = await fetch(`${apiUrl}/api/config`);
  let settingsData;
  try {
    settingsData = (await res.json()) || {};

    if (settingsData.campeonatos) {
      settingsData.campeonatos.map((campeonato, i) => {
        if (i == 0) {
          settingsData.campeonatos = {};
        }

        settingsData.campeonatos[
          createChampionshipHash(campeonato, ["codigo_pais", "campeonato", "esporte"])
        ] = campeonato;
      });
    }
  } catch (e) {
    settingsData = {};
  }

  const defaultSettings = {
    nome: process.env.APP_NAME,
    premio_maximo: 10000,
    multiplicador_maximo: 500,
    aposta_simples: 2,
    menor_aposta: 2,
    maior_aposta: 500,
    minimo_apostas: 1,
    maximo_apostas: 5,
    compartilhar_comprovante: false,
    reimprimir_aposta: false,
    reimprimir_ultima_aposta: false,
    dias_aposta: 7,
    fechamento_domingo: true,
    tempo_bloqueio_ao_vivo: 90,
    campeonatos: [],
    categorias: null,
  };

  Object.assign(defaultSettings, settingsData);

  return {
    props: {
      defaultSettings,
      apiUrl,
      socketUrl,
      oddsUrl,
    },
  };
}

export function shareMessage(message) {
  if (isRunningOnApp()) {
    callNative("shareMessage", message);
    return;
  }

  openWhatsApp(message);
}

export function showAttendance(settings) {
  if (typeof fbq !== 'undefined') {
    fbq('track', 'View Content');
  }

  let phone = settings.numero_atendimento.replace(/[^0-9]/g, "");

  if (settings.numero_atendimento.indexOf('+') === -1) {
    phone = `55${phone}`;
  }

  openWhatsApp(settings.mensagem_atendimento, phone);
}

export function getDepositMethod(method) {
  const methods = ["Transferência", "Pix", "Bônus"];
  const index = method - 2;

  return methods[index] ? methods[index] : null;
}

export function copyText(text) {
  if (isRunningOnApp()) {
    callNative("copyText", text);
    return;
  }

  if (typeof navigator.clipboard === 'undefined') {
    var input = document.createElement("textarea");
    input.style.position = "fixed";
    input.style.top = 0;
    input.style.left = 0;
    input.style.width = "2em";
    input.style.height = "2em";
    input.style.padding = 0;
    input.style.border = "none";
    input.style.outline = "none";
    input.style.boxShadow = "none";
    input.style.background = "transparent";
    input.value = text;

    document.body.appendChild(input);
    input.focus();
    input.select();

    try {
      var successful = document.execCommand("copy");

      if (successful) {
        document.getSelection().removeAllRanges()
        alertify.alert("Sucesso", "O texto foi copiado com sucesso");
      }
    } catch (err) {
      alertify.alert("Erro", "Não foi possível copiar o texto");
    }

    document.body.removeChild(input);

    return;
  }

  navigator.clipboard.writeText(text).then(() => {
    alertify.alert("Sucesso", "O texto foi copiado com sucesso");
  });
}

export function stringDiff(first, second) {
  let diff = "";

  second.split("").forEach((value, index) => {
    if (value != first.charAt(index)) {
      diff += value;
    }
  });

  return diff;
}

export function isNumber(value) {
  return typeof value === "number";
}

export function padStart(value, places = 2, string = '0') {
  value = value.toString();

  if (!value.padStart) {
    return (`${string}${value}`).slice(-places);
  }

  return value.padStart(places, string);
}

import classnames from 'classnames'

export default function PageSpinner({ isDarkTheme }) {
  return (
    <div className={classnames(['page-loading', { 'light': !isDarkTheme }])}>
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
